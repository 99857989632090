import {Container, Image, Nav, Navbar} from "react-bootstrap";
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {Loading} from "./Networking";
import logo from "./logo.png"



export function TabHeader({defaultTab}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false)
    const tabURL = location.pathname.split("/")[1]

    return (
        <>
            <Navbar expanded={isOpen} expand="lg" className="bg-body-tertiary" sticky={"top"} >
                <Container>
                    <Navbar.Brand><Image src={logo} width={100} height={100}/></Navbar.Brand>
                    <Navbar.Toggle onClick={() => {
                        setIsOpen(!isOpen)
                    }} aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav defaultActiveKey={tabURL ? tabURL : "Home"} className="me-auto" onSelect={(key) => {
                            navigate("/" + key.toString())
                            setIsOpen(false)
                        }}>
                            <Nav.Link eventKey={"Home"}>Home</Nav.Link>
                            <Nav.Link eventKey={"Projects"}>Projects</Nav.Link>
                            <Nav.Link eventKey={"Socials"}>Socials</Nav.Link>
                            <Nav.Link eventKey={"About"}>About</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet/>
        </>
    )
}

export function Categories() {
    const navigate = useNavigate()
    const location = useLocation()
    const categoryURL = location.pathname.split("/")[2]
    return (
        <>
        <Nav variant="pills" defaultActiveKey={ categoryURL ? categoryURL : "CatNotDog" } className="justify-content-center" onSelect={(key) => {
            navigate("/Store/" + key.toString())
        }}>
            <Nav.Link className={"categoriesButtons"} eventKey={"CatNotDog"}>Cat Not Dog</Nav.Link>
            <Nav.Link className={"categoriesButtons"} eventKey={"RecoveryRodeo"}>Recovery Rodeo</Nav.Link>
            <Nav.Link className={"categoriesButtons"} eventKey={"MammalMeetup"}>Mammal Meetup</Nav.Link>
        </Nav>
        <Outlet/>
            </>
    )
}