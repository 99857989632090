import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {RouterProvider, createBrowserRouter, Navigate} from "react-router-dom";
import {HomePage} from "./Home";
import {TabHeader} from "./TabHeader";
import {ProjectsPage} from "./Projects";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
        path: "*",
        element: <TabHeader/>,
        errorElement: <b>Error Page</b>,
        children: [
            {
                path: "",
                element: <Navigate to={"Home"}/>
            },
            {
                path: "Home",
                element: <HomePage/>
            },
            {
                path: "Projects",
                element: <ProjectsPage/>
            },
        ]
    },
])
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
