import {Card, Container, Nav, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export function ProjectsPage() {
    return (
        <Row style={{width: "100%"}}>
            <Card style={{width: '18rem'}} className={"mx-auto"}>
                <Card.Img variant="top" src={"https://firebasestorage.googleapis.com/v0/b/catnotdog-a4b15.appspot.com/o/CatNotDogLogo.png?alt=media&token=ffef0974-5405-44f3-88f2-0a802013f11a"}/>
                <Card.Body>
                    <Card.Title>{"CatNotDog.org"}</Card.Title>
                    <Card.Text>CatNotDog.org is a website made for my made up character
                        Cat not dog. This site has over 60 Cat Not Dogs I've collected as well
                        as a fully working merch shop which uses a completely custom backend
                        which includes webhooks to distributors as well as a working payment system
                        using Stripe. All made with Google Firebase as well as react for
                        the frontend and Cloud Functions for backend
                    </Card.Text>
                    <Link to={"https://Catnotdog.org"}>Go</Link>
                </Card.Body>
            </Card>
            <Card style={{width: '18rem'}} className={"mx-auto"}>
                <Card.Img variant="top" src={"https://firebasestorage.googleapis.com/v0/b/tonetrades-c1612.appspot.com/o/ToneTradesLogo.png?alt=media&token=6904ffb1-d8b2-4cd6-a6de-80cd60a38f17"}/>
                <Card.Body>
                    <Card.Title>{"ToneTrades.com"}</Card.Title>
                    <Card.Text>ToneTrades is a stock market for music</Card.Text>
                    <Link to={"https://tonetrades.com"}>Go</Link>
                </Card.Body>
            </Card>
        </Row>
    )
}